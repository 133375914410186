export default `
  query productVariantQuery($filter: ProductDetailFilterInput!, $configurations: [ID!]) {
    productDetail(filter: $filter) {
      configurable_product_options_selection(configurableOptionValueUids: $configurations) {
        variant {
          nontrasferibile
        }
      }
    }
  }
`;
