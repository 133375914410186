import { useRecaptcha } from '~/composables';

const formSpree = () => {
  const { isEnabled: isRecaptchaEnabled, $recaptcha } = useRecaptcha();
  const sendForm = async (formId, formObject) => {
    let recaptchaToken = '';
    if (isRecaptchaEnabled.value) {
      recaptchaToken = await $recaptcha.execute(`formspree_submission_${formId}`);
    }
    const endpoint = `https://formspree.io/f/${formId}`;
    const formData = new FormData();

    const cvFile = document.querySelectorAll('input#cv')?.[0]?.files?.[0];
    Object.keys(formObject).forEach((key) => {
      // if key === 'cv' then the value is a File object
      if (key === 'cv' && cvFile) {
        formData.append(key, cvFile);
        return;
      }
      formData.append(key, formObject[key]);
    });
    if (isRecaptchaEnabled.value) {
      formData.append('g-recaptcha-response', recaptchaToken);
    }
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });
      if (response.ok) return true;
    } catch (error) {
      console.warn(error);
    }
    return false;
  };

  return { sendForm };
};

export default formSpree;
