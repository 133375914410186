import { useContext, useRoute } from '@nuxtjs/composition-api';
import { Config } from '@gemini-vsf/api-client';

interface LocalConfig extends Config {
  geminiBaseUrl: string;
}

const getPath = (fullPath: string) => {
  const pathArray = fullPath.replace(/\/$/, '').split('/');
  if (pathArray.length >= 3) {
    return `/${pathArray?.slice(2).join('/')}`;
  }
  return '';
};

export default () => {
  const {
    app: {
      $vsf: {
        $gemini: { config },
      },
    },
  } = useContext();
  const { geminiBaseUrl } = config as LocalConfig;
  const route = useRoute();
  const { fullPath, path } = route.value;
  const getAlternates = () => {
    return [
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${geminiBaseUrl || 'https://www.colfert.com'}/it${getPath(fullPath)}`,
      },
      {
        rel: 'alternate',
        hreflang: 'it',
        href: `${geminiBaseUrl || 'https://www.colfert.com'}/it${getPath(fullPath)}`,
      },
      /* {
        rel: 'alternate',
        hreflang: 'en_IT',
        href: `${geminiBaseUrl || 'https://www.colfert.com'}/en${getPath()}`,
      }, */
    ];
  };

  const getCanonical = () => ({
    rel: 'canonical',
    href: `${geminiBaseUrl || 'https://www.colfert.com'}${path}`,
  });

  return {
    getAlternates,
    getCanonical,
  };
};
