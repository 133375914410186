export const userErrorMap = {
  updateUser: 'Update user',
  register: 'Register',
  login: 'Login',
  logout: 'Logout',
  changePassword: 'Change password',
  load: 'Loading',
};

export const checkoutErrorsMap = {
  shipping: {
    load: {
      title: 'Loading error',
      message: 'Could not load shipping information',
    },
    save: {
      title: 'Saving error',
      message: 'Could not save shipping information',
    },
  },
  billing: {
    load: {
      title: 'Loading error',
      message: 'Could not load billing information',
    },
    save: {
      title: 'Saving error',
      message: 'Could not save billing information',
    },
  },
  payment: {
    make: {
      title: 'Payment error',
      message: 'Could not make payment',
    },
    make_50: {
      title: 'Payment error',
      message: 'Please retry',
    },
    make_50_100: {
      title: 'Payment error',
      message: 'Your cart is outdated and it will be reloaded now.',
    },
    make_100_200: {
      title: 'Payment error',
      message: 'Could not make payment',
    },
    make_200: {
      title: 'Payment error',
      message: 'Could not make payment',
    },
  },
  thankYou: {
    generatePdf: {
      title: 'PDF generation error',
      message: 'There was an error generating the PDF order summary',
    },
  },
};

export const filterLabelsMap = {
  alimentazione: 'Alimentazione',
  altezza: 'Altezza',
  altezzabordo: 'Altezza bordo',
  altezzagraffa: 'Altezza graffa',
  altezzaspazz: 'Altezza spazzole',
  ambienteutilizzo: 'Ambiente utilizzo',
  amperaggio: 'Amperaggio',
  apertura: 'Apertura',
  applicazione: 'Applicazione',
  applicazioneolio: 'Applicazione olio',
  aspirazione: 'Aspirazione',
  asse: 'Asse',
  autocleaning: 'Auto cleaning',
  azionamento: 'Azionamento',
  capienzacar: 'Capienza car',
  capserbatoio: 'Capacità serbatoio',
  cerniere: 'Cerniere',
  ciecoforato: 'Cieco forato',
  classe: 'Classe',
  classeantief: 'Classe antieffrazione',
  classepolveri: 'Classe polveri',
  classeprot: 'Classe protezione',
  classeresistenza: 'Classe resistenza',
  codolo: 'Codolo',
  color: 'Colore',
  colore: 'Colore',
  coloretelaio: 'Colore telaio',
  corsa: 'Corsa',
  destdiuso: "Destinazione d'uso",
  diametro: 'Diametro',
  diametrocavo: 'Diametro cavo',
  diametroesterno: 'Diametro esterno',
  diametrofiletto_1: 'Diametro filetto',
  diametrogambo_1: 'Diametro gambo',
  diametrointerno: 'Diametro interno',
  diametromassimo: 'Diametro massimo',
  diametroperno: 'Diametro perno',
  diametroruota: 'Diametro ruota',
  diametrotubo: 'Diametro tubo',
  diametrougello: 'Diametro ugello',
  dimensione: 'Dimensione',
  distanza: 'Distanza',
  entrata: 'Entrata',
  fermo: 'Fermo',
  finitura: 'Finitura',
  forma: 'Forma',
  formapiastra: 'Forma piastra',
  forochiave: 'Foro chiave',
  forza: 'Forza',
  forzachiusura: 'Forza chiusura',
  frontale: 'Frontale',
  gradazione: 'Gradazione',
  grana: 'Grana',
  impronta: 'Impronta',
  installazione: 'Installazione',
  interasse: 'Interasse',
  interassefori: 'Interasse fori',
  larghezza_1: 'Larghezza',
  larghezzacodolo: 'Larghezza codolo',
  larghezzagraffa: 'Larghezza graffa',
  larghezzalama: 'Larghezza lama',
  larghezzataglio: 'Larghezza taglio',
  litraggio: 'Litraggio',
  litriariaerogati: 'Litri aria erogati',
  lumen: 'Lumen',
  marchio: 'Marchio',
  materiale: 'Materiale',
  materialedest: 'Materiale destinazione',
  misura: 'Misura',
  misuraattacco: 'Misura attacco',
  misuracamera: 'Misura camera',
  misurafrontale: 'Misura frontale',
  misuragiunto: 'Misura giunto',
  misurapiastra: 'Misura piastra',
  misuraquadro: 'Misura quadro',
  misurautile: 'Misura utile',
  misurazanca: 'Misura zanca',
  numerocanali: 'Numero canali',
  numerocolpi: 'Numero colpi',
  numerodenti: 'Numero denti',
  numerofori: 'Numero fori',
  numerogiri: 'Numero giri',
  numerogradini: 'Numero gradini',
  numeroposti: 'Numero posti',
  numeroprese: 'Numero prese',
  numeroscatti: 'Numero scatti',
  numerotaglienti: 'Numero taglienti',
  passolamella: 'Passo lamella',
  percussione: 'Percussione',
  peso: 'Peso',
  pesoconfezione: 'Peso confezione',
  pesoinfisso: 'Peso infisso',
  piastra: 'Piastra',
  portata: 'Portata',
  portataacqua: 'Portata acqua',
  potenzaerogata: 'Potenza erogata',
  potenzaj: 'Potenza in joule',
  potenzalaser: 'Potenza laser',
  potenzaw_1: 'Potenza in watt',
  pressione: 'Pressione',
  prestazione: 'Prestazione',
  profonditataglio_1: 'Profondita taglio',
  puntidichiusura: 'Punti di chiusura',
  raggiorotazione: 'Raggio rotazione',
  riutilizzo: 'Riutilizzo',
  serieprofilo: 'Serie profilo',
  spessoremm: 'Spessore in mm',
  spessoreporta: 'Spessore porta',
  spessorevetro: 'Spessore vetro',
  spestestachiodo: 'Spessore testa chiodo',
  stagione: 'Stagione',
  taglia: 'Taglia',
  tagliafuoco: 'Taglia fuoco',
  tempmassima: 'Temperatura massima',
  testa: 'Testa',
  tipoaliment: 'Tipo alimentazione',
  tipoapertura: 'Tipo apertura',
  tipoarresto: 'Tipo arresto',
  tipobloccaggio: 'Tipo bloccaggio',
  tipocardine: 'Tipo cardine',
  tipocilindro: 'Tipo cilindro',
  tipocomando: 'Tipo comando',
  tipocuscinetto: 'Tipo cuscinetto',
  tipofiletto: 'Tipo filetto',
  tipoinfisso: 'Tipo infisso',
  tipologia: 'Tipologia',
  tipologiaattacco: 'Tipologia attacco',
  tipologiachiodi: 'Tipologia chiodi',
  tipologiainfisso: 'Tipologia infisso',
  tipologiarivetto: 'Tipologia rivetto',
  tipologiarullo: 'Tipologia rullo',
  tipologiatesta: 'Tipologia testa',
  trasmissionedati: 'Trasmissione dati',
  velocita: 'Velocita',
  velocitacarica: 'Velocita carica',
  voltaggio: 'Voltaggio',
};
