const discountsTable = [
  { from: 0.01, to: 1.76, discount: 0.35 },
  { from: 1.760_001, to: 2.12, discount: 0.36 },
  { from: 2.120_001, to: 2.57, discount: 0.37 },
  { from: 2.570_001, to: 2.72, discount: 0.38 },
  { from: 2.720_001, to: 3.15, discount: 0.39 },
  { from: 3.150_001, to: 3.49, discount: 0.4 },
  { from: 3.490_001, to: 3.77, discount: 0.41 },
  { from: 3.770_001, to: 4.1, discount: 0.42 },
  { from: 4.100_001, to: 4.35, discount: 0.43 },
  { from: 4.350_001, to: 4.78, discount: 0.44 },
  { from: 4.780_001, to: 5.14, discount: 0.45 },
  { from: 5.140_001, to: 5.48, discount: 0.46 },
  { from: 5.480_001, to: 5.84, discount: 0.47 },
  { from: 5.840_001, to: 6.41, discount: 0.48 },
  { from: 6.410_001, to: 6.52, discount: 0.49 },
  { from: 6.520_001, to: 6.84, discount: 0.5 },
  { from: 6.840_001, to: 10.23, discount: 0.51 },
  { from: 10.230_001, to: 11.66, discount: 0.52 },
  { from: 11.660_001, to: 17.03, discount: 0.53 },
  { from: 17.030_001, to: 34.04, discount: 0.54 },
  { from: 34.040_001, to: 68.09, discount: 0.55 },
  { from: 68.090_001, to: 102.02, discount: 0.56 },
  { from: 102.020_001, to: 139.88, discount: 0.57 },
  { from: 139.880_001, to: 170.04, discount: 0.58 },
  { from: 170.040_001, to: 204.05, discount: 0.59 },
  { from: 204.050_001, to: 238.11, discount: 0.6 },
  { from: 238.110_001, to: 269.04, discount: 0.604 },
  { from: 269.040_001, to: 306.43, discount: 0.608 },
  { from: 306.430_001, to: 340.49, discount: 0.612 },
  { from: 340.490_001, to: 1020.66, discount: 0.616 },
  { from: 1020.660_001, to: 3449.3, discount: 0.62 },
  { from: 3449.300_001, to: 7359.9, discount: 0.624 },
  { from: 7359.900_001, to: 12_648, discount: 0.628 },
  { from: 12_648.000_001, to: 1_251_200, discount: 0.632 },
];

const defaultBundleDiscount = 0.06;

export const getDiscountedPrice = (basePrice: number, qty = 1) => {
  const rowPrice = basePrice * qty;

  const foundDiscount = discountsTable.find((discountRange) => rowPrice >= discountRange.from && (rowPrice <= discountRange.to || !discountRange.to));

  return foundDiscount ? basePrice * (1 - foundDiscount.discount) : basePrice;
};

export const getBundleDiscountedPrice = (price: number, bundleQty = 1, qty = 1, discount = defaultBundleDiscount) => {
  if (bundleQty === 1 || qty < bundleQty) {
    return price;
  }

  const invertedDiscount = 1 - discount;

  const remainder = qty % bundleQty;
  if (remainder === 0) {
    return price * invertedDiscount;
  }

  const realDiscount = 1 - (remainder + invertedDiscount * (bundleQty - remainder)) / bundleQty;

  return price * (1 - realDiscount);
};

export const getSplittedBundledPrice = (rowTotalPrice: number, bundleQty: number, qty: number, discount = defaultBundleDiscount) => {
  const remainder = qty % bundleQty;
  const unitPrice = rowTotalPrice / (qty - discount * (qty - remainder));
  const remainderPrice = unitPrice * remainder;

  return {
    bundledQty: qty - remainder,
    bundledPrice: rowTotalPrice - remainderPrice,
    packageQty: Math.floor(qty / bundleQty),
    remainingQty: remainder,
    remainingPrice: remainderPrice,
  };
};
