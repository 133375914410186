import { computed, useRoute } from '@nuxtjs/composition-api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { productGetters, useProduct } from '@gemini-vsf/composables';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ConfigurableProductOptions, SwatchData } from '@gemini-vsf/api-client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Product } from '@gemini-vsf/api-client/lib/types/GraphQL';
import { isArray } from 'lodash-es';
import { ColfertProduct } from '~/types/types';

export const productData = () => {
  const route = useRoute();
  const {
    params: { path: productSlug },
    query,
  } = route.value;
  const cacheId = `product-${productSlug}`;
  const { products, loading } = useProduct(cacheId);

  return {
    cacheId,
    loading,
    product: computed(() => {
      const baseProduct = Array.isArray(products?.value?.items) && products?.value?.items[0] ? products?.value?.items[0] : [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const filteredProduct = productGetters.getFiltered(baseProduct, {
        master: true,
        attributes: query,
      });

      return isArray(filteredProduct) ? null : filteredProduct;
    }),
  };
};

export const getSfColor = (swatchData: SwatchData[] | SwatchData) => {
  let sd: SwatchData;

  if (Array.isArray(swatchData)) {
    if (swatchData.length === 0) {
      return '';
    }
    [sd] = swatchData;
  } else {
    sd = swatchData;
  }

  const hc = productGetters.getSwatchDataHexCode(sd);
  const t = productGetters.getSwatchDataThumbnail(sd);
  if (t === undefined) {
    return hc;
  }
  return `url("${t}") center center / contain no-repeat`;
};

export const getSfColorsFromAttributes = (attribute: ConfigurableProductOptions) =>
  attribute.values.map((o) => ({
    value: o.uid,
    color: getSfColor(o.swatch_data),
    label: o.label,
  }));

// eslint-disable-next-line consistent-return
export const getProductColorAttribute = (product: Product) => {
  if (product?.configurable_options) {
    return product?.configurable_options.find((option) => option.attribute_code === 'colore' || option.attribute_code === 'attribute1');
  }
};

export const getProductTitle = (product: ColfertProduct) => {
  const titleString =
    productGetters.getShortDescription(product) === '' ? productGetters.getName(product) : productGetters.getShortDescription(product);
  return titleString?.replaceAll('<p>', '')?.replaceAll('</p>', '');
};
